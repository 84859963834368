<template>
  <div id="app" :class="theme">
    <router-view></router-view>
  </div>
</template>

<script>

import { mapState } from 'vuex'
export default {
  name: 'App',
  components: {
  },
  computed: {
    ...mapState(["isAndroid", "isIOS", "basicInfo"])
  },
  data() {
    return {
      deviceIOS: false,
      deviceAndroid: false,
      theme: '',
    }
  },
  created() {
    this.deviceIOS = navigator.userAgent.match(/(iPhone|iPod|iPad);?/i);
    this.deviceAndroid = navigator.userAgent.match(/android/i);
    this.$store.commit('SET_DEVICE_ANDROID', this.deviceAndroid);
    this.$store.commit('SET_DEVICE_IOS', this.deviceIOS);
  },
  methods: {
    init() {
      this.$store.dispatch('getBasicInfo').then((res) => {
        this.$store.commit('SET_BASIC_INFO', res.data);
        this.theme = this.basicInfo.theme;
        // 创建一个新的 link 元素
        var linkElement = document.createElement('link');

        // 设置 link 元素的属性
        linkElement.rel = 'shortcut icon';
        linkElement.href = this.basicInfo.logo_s; // 替换为你的 favicon.ico 文件的路径

        // 获取文档头部
        var headElement = document.head || document.getElementsByTagName('head')[0];

        // 删除现有的 favicon，如果存在
        var existingFavicon = document.querySelector("link[rel='shortcut icon']");
        if (existingFavicon) {
          headElement.removeChild(existingFavicon);
        }

        // 将新的 link 元素添加到文档头部
        headElement.appendChild(linkElement);

      })
    }
  },
  mounted() {
    this.init();
  }
}
</script>

<style lang="less">
#app {
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;

  &.black {
    background: #293454;
  }

  &.white {
    background: #eef5fe;
  }
}
</style>
